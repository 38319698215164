import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';

import { ListGroup, Container, Row, Col } from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';
import { render } from 'react-dom';

import { connect } from 'react-redux';

import cookie from 'js-cookie';
import Logo from './../dist/images/logo-white.svg';

import avatar from './../dist/images/icons/avatar.png';
import NumberFormat from 'react-number-format';
import CountUp from 'react-countup';

class Structure extends Component {

    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            open: true,
            hidex: '',
            user: this.props.userInfo,
        };

    }

    state = {
        navStatus: 0,
    }

 
    openNav = () => {

        if (this.state.open == true) {
            this.setState({
                open: false,
                hidex: 'hidden'
            })

            document.getElementById('myNav').style.width = '310px';
            document.getElementById('mainBody').style.left = '310px';
            document.getElementById('mainBody').style.width = '500px';
            //tags[0].setAttribute('hidden', "hidden")
        }

        else {

            this.setState({
                open: true,
                hidex: 'visible'
            })
            document.getElementById('myNav').style.width = '0px'
            document.getElementById('mainBody').style.left = '0'
            document.getElementById('mainBody').style.width = '100%';
        }

    }

    handleLogout = (e) => {

        e.preventDefault();
        cookie.remove('token');
        cookie.remove('refresh');
        this.props.loggout();
        window.location.replace('/#/login');
        window.location.reload();
    }

    render() {

        const { open, hidex } = this.state;

        return (

            <div>

                <div className="">

                    <div className="myNav shadow-2xl" id="myNav" style={{}}>


                        <div className="sideNav">

                            <div className="text-left pb- primaryBg pt- shadow-sm" style={{ minHeight: '73px' }}>

                                <Link to="/">
                                    <span className="inline-block float-left">
                                        <img src={Logo} className="ml-2 mb-5 pt-2" width={130} />
                                    </span>
                                    <span className="inline-block mt-1 ml-3 text-black workSanMedium f-s-18"></span>
                                </Link>
                            </div>


                            <div className='profileBox px-4'>

                                <div className='grid grid-cols-12 gap-5'>
                                    <div className='col-span-5'>
                                        <img src={avatar} width={70} className='float-right' />
                                    </div>
                                    <div className='col-span-7'>
                                        <div className='workSanBold text-white pb-3 mt-3 border-b border-gray-500'>
                                            <span className='workSanBold inline-block'>Hi, {this.props.userInfo.firstname}</span>
                                            <div className='inline-block py-1 px-2 bg-white ml-3 secondaryColor text-xs' style={{ borderRadius: '20px' }}>Active</div>
                                        </div>

                                        <div className='workSanBold mt-3 text-white'>
                                            <span>Wallet Balance:</span>
                                        </div>

                                        <div className='flex flex-row mt-1'>
                                            <span className='text-white f-ubuntu flex-1'>

                                                {/* <NumberFormat thousandSeparator={true} prefix={'₦'} value={this.props.userInfo.wallet_balance} displayType="text" /> */}

                                                <CountUp end={this.props.userInfo.wallet_balance} separator="," prefix={'₦'} className="text-white f-s-30 font-bold" />
                                            </span>
                                            <button className='text-white'>
                                                <FeatherIcon icon='eye' size={20} />
                                            </button>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <ul className="mt-5 border-r">
                                <li className="navx"><NavLink activeClassName="active" to="/dashboard">  <i className="fa fa-home"></i>  <span className="navText mulishBold"> Home </span> </NavLink></li>

                                <li className="navx" ><NavLink to="/profile">  <i className="fa fa-user"></i>   <span className="navText mulishBold"> My Profile </span></NavLink></li>
                                <li className="navx" ><NavLink to="/history">  <i className="fa fa-bookmark"></i>   <span className="navText mulishBold"> History </span></NavLink></li>
                                <li className="navx" ><NavLink to="/settings"> <i className="fa fa-cog"></i>  <span className="navText mulishBold">Settings </span></NavLink></li>



                                <li className=""><Link to="#" onClick={this.handleLogout}><FeatherIcon icon="log-out" className="inline-block f-s-20" hidden /> <i className="fa fa-sign-out-alt"></i><span className="navText mulishBold">Logout</span> </Link></li>
                            </ul>

                            <div className='bottom-0 absolute'>

                                <div className="mt-4 text-center mx-auto workSan text-white">
                                    <p>
                                        <i className="fa fa-phone primaryColor mr-1 text-xs"></i> +234 704 800 0480
                                        &emsp;
                                        <br />
                                        <i className="fa fa-envelope primaryColor mr-1 text-xs"></i> support@pay1naija.com
                                    </p>

                                    <p className="text-center text-xs">Copyright © 2023 Pay1Naija. All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="mainBody pb-4" id="mainBody">
                    <div className="structure-header lg:hiddenx bg-whitex shadow-sm">

                        <Container>
                            <Row>

                                <Col xs={2} sm={3}>
                                    <div className="pl-3 pt-4 mb-4 mobileView">
                                        <a onClick={this.openNav}>
                                            <FeatherIcon icon="menu" className="text-white" style={{ fontSize: '50px' }} />
                                        </a>
                                    </div>
                                </Col>

                                <Col xs={7} sm={10}>
                                    <div className="float-left inline-block pt-4" hidden>
                                        <Link onClick={this.openNav}>
                                            <FeatherIcon icon="menu" className="text-gray-300" style={{ fontSize: '50px' }} />
                                        </Link>
                                    </div>

                                    <div className="pt-4 text-center">
                                        <h6 style={{ fontWeight: 'bolder' }} className={this.props.colorx + " text-center f-s-18 workSanMedium f-w-700 p-t-8 workSan"}>{this.props.title} </h6>
                                    </div>
                                </Col>

                                <Col xs={3} sm={2}>
                                    <div className="pt-4 flex gap-4x flex-row">


                                        <div className="mr-3 moveLeft">
                                            {/* <Link to="notifications" className="text-pink-800">

                                                <FeatherIcon icon="bell" size={30} className="fa fa-bell fa-3x block z-0 relative" style={{ marginTop: '3px', marginLeft: '-20x' }} />
                                                <span className="bg-gray-700 w-6 h-6 block text-center rounded-full z-50 absolute f-ubuntu text-white" style={{ marginTop: '-38px', marginRight: '5px' }}>
                                                    {/* {this.props.userInfo.notification} }
                                                </span>
                                            </Link> */}
                                        </div>



                                        <div className="bg-gray-100 h-8 w-8 rounded-full mr-4">
                                            {
                                                !this.props.userInfo.picture ?

                                                    // <img src={require('./../dist/images/icons/user.png')} />
                                                    <div className='w-9 h-9 bg-pink-800 rounded-full text-center mulishBold py-2 mlAvatar' style={{ width: '40px', height: '40px', marginTop: '-4px' }}>
                                                       <span> {this.props.userInfo.firstname[0] + '' + this.props.userInfo.lastname[0]} </span>
                                                    </div>
                                                    :
                                                    <img src={this.props.userInfo.picture} />

                                            } 
                                        </div>
                                        <span className="workSanMedium mt-1 hidden sm:block text-white">
                                            {this.props.userInfo.firstname + ' '}
                                        </span>
                                        <FeatherIcon icon="chevron-down" className="mt-1" />

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="lg:pt-4 bg-gray-200" style={{ position: 'relative', minHeight: '100vh' }}>
                        {this.props.children}
                    </div>
                </div>


            </div>
        );
    }
}


const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Structure);




//export default Structure;