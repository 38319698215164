import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

//import logo from './logo.svg';
import './dist/css/bootstrap.min.css';
import './dist/css/pay1naija.css';
import './dist/css/pay1naija-media.css';

import GuestRoute from './components/GuestRoute';
import AuthRoute from './components/AuthRoute';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Structure from './inc/structure';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';



const loading2 = (
  <div className="pt-56 my-0 text-center py-64 h-screen">

    <div className=''>Loading....</div>

    {/* <img src={require('./dist/images/animation/sn2.gif')} width={150} className="m-auto" /> */}

  </div>
);


const Landing = React.lazy(() => import('./Pages/Landing'));
const Login = React.lazy(() => import('./auth/Login'));
const Register = React.lazy(() => import('./auth/Register'));
const Forgotten = React.lazy(() => import('./auth/Forgotten'));
const Dashboard = React.lazy(() => import('./home/Dashboard'));
const Profile = React.lazy(() => import('./home/Profile'));
const History = React.lazy(() => import('./home/History'));
const Settings = React.lazy(() => import('./home/Settings'));




function App() {
  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading2}>
          <Switch>
            <Route path='/' exact component={Landing} />
            
            <GuestRoute path='/login' component={Login} />
            <GuestRoute path='/register' component={Register} />
            <GuestRoute path='/register' component={Register} />
            <GuestRoute path='/forgotten-password' component={Forgotten} />
            

            <Structure>
              <AuthRoute path='/dashboard' component={Dashboard} />
              <AuthRoute path='/profile' component={Profile} />
              <AuthRoute path='/history' component={History} />
              <AuthRoute path='/settings' component={Settings} />
            </Structure>

            
          </Switch>
        </React.Suspense>
        <ToastContainer className="f-visby" />
      </HashRouter>
    </>

  );
}

export default App;

/*
    <Router>      
      <GuestRoute path="/" exact component={Landing} />      
    </Router>
*/