import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './dist/css/tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import store from './store/index';
import { Provider } from 'react-redux';
import axios from 'axios';
import cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
import { ServerLink } from './keys/Links';

const jwt_secret = 'aziTtU29jAbEHwVaVXYp3Bmiun1ua2Zagzcn0loPCzBYYjtV4Uj10hdAfU5Bsgk6'; //for the live jwt
let token = cookie.get("token");


const Render = () => {

  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  );


  /*
    ReactDOM.render(
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>,
      document.getElementById('root')
    );
  */
}


//console.log(cookie.get("token"));

if (token) {

  //console.log("token wa")
  jwt.verify(token, jwt_secret, (err, decoded) => {

    if (err) {
      //console.log('remove token 1')
      cookie.remove("token");
      token = null;

    }
    else {
      if (ServerLink + 'api/register' !== decoded.iss && ServerLink + 'api/login' !== decoded.iss) {

        //console.log("Help");
        cookie.remove("token");
        token = null;

        //console.log(decoded.iss)
        //console.log(ServerLink+'/register')
      }
    }

    //console.log(decoded);
  });
}



if (token) {

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.get(ServerLink + 'api/user').then(
    res => {
      store.dispatch({ type: "SET_LOGIN", payload: res.data.data });
      console.log(res.data.data)
      Render();
    });
}

else {


  //console.log('hello');

  Render();


}





/*



  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
*/


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
